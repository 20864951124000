.subscription-container {
  background-image: var(--subscription-wood-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 3rem;
}

.subscription-products {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  background-image: var(--subscription-corkboard-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 2rem;
  padding-bottom: 2rem;
  min-height: 50vh;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: #F5F5DC;
}

.loading, .error, .no-products {
  text-align: center;
  font-size: 1.2rem;
  margin: 2rem 0;
  color: #666;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.error {
  color: #e74c3c;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
}

.product-card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0,0,0,0.15);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eaeaea;
}

.product-info {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #333;
}

.product-description {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.see-more-button {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0;
  margin-bottom: 1rem;
  align-self: flex-start;
  transition: color 0.3s ease;
}

.see-more-button:hover {
  color: #2980b9;
  text-decoration: underline;
}

.product-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #eaeaea;
}

.product-price {
  font-size: 1.2rem;
  font-weight: 700;
  color: #2ecc71;
}

.buy-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  font-weight: 600;
}

.buy-button:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .products-grid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: 1.8rem;
  }
  
  .product-card {
    max-width: 100%;
  }
}