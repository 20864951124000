/* General Layout */
.admin-container {
  display: flex;
  font-family: 'Arial', sans-serif;
  background-color: #f4f5f7;
}

.sidebar {
  width: 250px;
  background-color: #1e1e2d;
  padding: 20px;
  color: #fff;
  padding-top: 6rem;
}

.sidebar h2 {
  color: #fff;
  text-align: center;
  line-height: 45px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin-top: 40px;
}

.sidebar ul li {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: #3b3b50;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f4f5f7;
  padding-top: 6rem;
  /* margin-left: 250px; */
}

header {
  margin-bottom: 20px;
}

header h1 {
  color: #333;
  font-size: 28px;
}

.stats-container {
  display: flex;
  gap: 20px;
  margin-top: 1rem;
  width: 100%;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.card h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 20px;
}

.card p {
  font-size: 16px;
  color: #666;
}

.route-button {
  margin-top: 2rem;
  font-weight: bold;
  border-radius: 8px;
}

.message-container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.message-container h2 {
  font-size: 24px;
  margin-bottom: 1rem;
  text-align: center;
}

.message-container textarea {
  width: 100%;
  height: 10rem;
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  resize: none;
}

.message-container textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.message-container button {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calendar-container {
  margin: 20px;
}

.delivery-calendar {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.selected-date-deliveries {
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-date-deliveries ul {
  list-style: none;
  padding: 0;
}

.selected-date-deliveries li {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.selected-date-deliveries li:last-child {
  border-bottom: none;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .admin-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }

  .stats-container {
    flex-direction: column;
  }

  .main-content {
    padding: 20px 0 20px;
  }

  header h1 {
    padding-left: 20px;
  }

  .calendar-container {
    margin: 20px 0 20px;
    order: -1;
  }

  .calendar-container>.card {
    padding: 20px 0 20px;
    margin-bottom: 20px;
  }

  .card:hover {
    transform: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .burger-menu {
    display: block !important;
  }

  .admin-sticky-banner {
    display: flex;
    justify-content: flex-end;
  }

  .admin-sticky-banner p {
    width: 80%;
  }


  .sidebar {
    position: fixed;
    left: -100vw;
    top: 0;
    height: 100vh;
    width: 100vw;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(100vw);
  }

  .selected-date-deliveries {
    margin: 20px;
    padding: 20px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .selected-date-deliveries.expanded {
    max-height: fit-content;
  }

  .selected-date-deliveries ul {
    padding: 0;
  }

  .selected-date-deliveries li {
    padding: 15px;
    margin-bottom: 15px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }

  .selected-date-deliveries li strong {
    display: inline-block;
    font-size: 1.1em;
    margin-bottom: 8px;
  }

  .selected-date-deliveries li p {
    margin: 5px 0;
  }

  .delivery-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 15px;
  }

  .action-button {
    width: 100%;
    padding: 10px;
  }

  .calendar-container {
    margin: 0 -20px;
  }

  .delivery-calendar {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .box-preferences-container {
    display: none;
  }

  .route-button {
    display: block;
    margin: 20px auto;
    width: 100%;
  }

  .phone-link {
    color: #28a745;
    font-weight: 500;
  }

  .phone-link:hover {
    color: #218838;
  }

}

.selected-date-deliveries .route-button {
  display: none;
}

/* Calendar Styles */
.react-calendar {
  width: 100%;
  max-width: 600px;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.delivery-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.action-button {
  flex: 1;
  padding: 8px 16px;
}

/* Modal calendar styles */
.modal-body .react-calendar {
  margin: 0 auto;
}

/* Add this new style for the sticky banner */
.admin-sticky-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fffae6;
  color: #856404;
  padding: 15px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar li {
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.sidebar li.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar li:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.users-section {
  padding: 20px;
}

.subscription-groups {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.subscription-group {
  padding: 20px;
}

.customer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.customer-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 40px;
}

.customer-card h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.customer-card p {
  margin: 5px 0;
  line-height: 1.4;
}

.customer-card p:last-child {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  font-style: italic;
  color: #666;
}

.status-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
}

.status-badge.active {
  background-color: #28a745;
  color: white;
}

.status-badge.paused {
  background-color: #ffc107;
  color: #000;
}

.status-badge.cancelled {
  background-color: #dc3545;
  color: white;
}

.status-badge.no-subscription {
  background-color: #6c757d;
  color: white;
}

.burger-menu {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  background: none;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.burger-menu:hover {
  transform: scale(1.1);
}

/* Add smooth transitions for all interactive elements */
.sidebar,
.burger-menu,
.selected-date-deliveries li {
  transition: all 0.3s ease-in-out;
}

/* Optional: Add a nice scale effect on button hover */
.action-button:hover {
  transform: scale(1.02);
  transition: transform 0.2s ease;
}

.phone-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.phone-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .phone-link {
    color: #28a745;
    font-weight: 500;
  }

  .phone-link:hover {
    color: #218838;
  }
}