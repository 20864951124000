.review-carousel-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

.review-carousel {
  display: flex;
  gap: 20px;
  padding: 0;
  background: transparent;
  margin-top: 2rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.review-carousel::-webkit-scrollbar {
  display: none;
}

.review-card {
  flex: 0 0 calc(20% - 16px);
  min-width: 280px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
  scroll-snap-align: start;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  transition: none;
}

.review-card__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0) 100%);
  color: white;
  text-align: center;
}

.review-card__name {
  font-size: 1.2em;
  margin: 0 0 5px;
}

.star-rating {
  color: #ffd700;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.review-card__text {
  font-size: 0.9em;
  line-height: 1.5;
  margin-bottom: 10px;
}

.review-card__read-more,
.review-card__read-less {
  color: #007bff;
  text-decoration: none;
  font-size: 0.9em;
  cursor: pointer;
}

.review-card__read-more:hover,
.review-card__read-less:hover {
  text-decoration: underline;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.carousel-arrow.left {
  left: 10px;
}

.carousel-arrow.right {
  right: 10px;
}

@media (max-width: 1400px) {
  .review-card {
    flex: 0 0 calc(25% - 15px);
  }
}

@media (max-width: 1100px) {
  .review-card {
    flex: 0 0 calc(33.333% - 14px);
  }
}

@media (max-width: 768px) {
  .review-card {
    flex: 0 0 70%;
  }
}

