.delivery-check {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: var(--linen-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
}

.map-and-form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.map-container {
    flex: 1;
    min-width: 50%;
}

.form-container {
    flex: 0.5;
    min-width: 300px;
    background-color: #222;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
}

.form-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.delivery-check form {
    margin: 1rem 0;
}

button {
    padding: 0.75rem 1.5rem;
    background-color: #DDA15E;
    color:black;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: bisque;
}

.alert {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid transparent;
    border-radius: 4px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.alert-success {
    background-color: #DDA15E;
    color: black;
}

.alert-success a {
    color: #000000;
}

.alert-info {
    background-color: #cce5ff;
    color: #155724;
}

@media (max-width: 768px) {
    .map-and-form-container {
        flex-direction: column;
    }

    .map-container {
        min-width: 100%;
    }

    .form-container {
        min-width: 100%;
        margin-top: 1rem;
    }
}