.founders-section {
    padding: 5rem 2rem;
    background-image: var(--friends-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .founders-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  }
  
  .founders-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .founder-card {
    text-align: center;
    padding: 1rem;
  }
  
  .founder-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 1.5rem;
  }
  
  .founder-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.4);
    padding: 0.5rem;
    border-radius: 4px;
  }
  
  .founder-bio {
    font-size: 1rem;
    line-height: 1.6;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.4);
    padding: 1rem;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .founders-section {
      background-image: var(--friends-bg-mobile);
    }
    
    .founders-grid {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    
    .founder-card {
      max-width: 400px;
      margin: 0 auto;
    }
  }