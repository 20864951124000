.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
    background-color: #1e1e2d;
    /* Updated to dark navy/midnight color */
    color: white;
    height: 100px;
    /* Add fixed height */
    display: flex;
    justify-content: flex-end;
    /* Align items to the right */
    align-items: center;
    padding: 0 20px;
}

.navbar-hidden {
    transform: translateY(-100%);
    pointer-events: none;
    /* Add opacity to ensure everything is hidden */
    opacity: 0;
}

.navbar-logo {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-10%);
    height: 80px;
    z-index: 1001;
    /* Add transition to match navbar animation */
    transition: all 0.3s ease-in-out;
}

.navbar-logo img {
    height: 100%;
    width: auto;
    object-fit: contain;
    border: 3px solid white;
    /* Adds a white border */
    box-sizing: border-box;
    /* Ensures border is included in the element's total size */
    padding: 2px;
    /* Optional: adds some space between the image and the border */
}

.navbar-links {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    margin: 0;
    padding-right: 20px;
    /* Add some padding from the right edge */
    width: auto;
    /* Remove any fixed width */
}

.navbar-links li {
    position: relative;
    padding: 0;
    margin: 0;
}

.navbar-links a {
    text-decoration: none;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    /* Add horizontal padding */
    transition: color 0.3s ease;
    text-transform: uppercase;
    white-space: nowrap;
    /* Prevent text wrapping */
}

.navbar-links a:hover {
    color: #FFA500;
    /* Orange accent color for hover state */
}

.navbar-links a::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #9C6D38;
    transition: width 0.3s ease;
}

.navbar-links a:hover::after {
    width: 100%;
}

.hamburger {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    z-index: 1100;
}

.bar {
    height: 3px;
    width: 100%;
    background-color: #DDA15E;
    transition: transform 0.3s ease;
}

.bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.bar.open:nth-child(2) {
    opacity: 0;
}

.bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px -5px);
}

/* Mobile View */
@media (max-width: 768px) {
    .navbar-links {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #1e1e2d;
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 1000;
    }

    .navbar-links.open {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    .hamburger {
        display: flex;
        z-index: 1100;
    }

    .navbar-links li {
        margin: 1.5rem 0;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease-in-out;
    }

    .navbar-links.open li {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
    }

    .navbar-links.open li:nth-child(1) {
        transition-delay: 0.1s;
    }

    .navbar-links.open li:nth-child(2) {
        transition-delay: 0.2s;
    }

    .navbar-links.open li:nth-child(3) {
        transition-delay: 0.3s;
    }

    .navbar-links.open li:nth-child(4) {
        transition-delay: 0.4s;
    }
}

.navbar-toggler {
    color: white;
}

/* Ensure menu items are visible on the darker background */
.nav-link {
    color: white !important;
}

.nav-link:hover {
    color: #FFA500 !important;
    /* Orange accent color for hover state */
}