.produce-carousel {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .produce-carousel__title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .produce-carousel__content {
    position: relative;
    margin-bottom: 20px;
  }
  
  .produce-carousel__image-container {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .produce-carousel__image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .produce-carousel__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .produce-carousel__nav--prev {
    left: 10px;
  }
  
  .produce-carousel__nav--next {
    right: 10px;
  }
  
  .produce-carousel__item-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .produce-carousel__item-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .produce-carousel__change-frequency {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }
  
  .produce-carousel__subscribe {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4a69bd;
    color: white;
    border: none;
    cursor: pointer;
  }