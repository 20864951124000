.main-page {
    margin: 0 auto;
    padding: 20px;
    padding-top: 6rem;
    background-image: var(--linen-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
  }
  
  .main-page h1 {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.9);
    color: #333;
  }
  
  .main-page p {
    text-align: center;
    padding-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.9);
    color: #333;
    font-size: 1.25rem;
  }
  
  .review-section {
    margin-bottom: 40px;
  }
  
  .content-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .delivery_section {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .content-section__right,
  .content-section__left {
    width: 100%;
  }
  
  .review_section {
    width: 100%;
    margin: 2rem 0;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .review-carousel-container h2 {
    color: #36454F;
  }

  
  @media (max-width: 768px) {
    .content-section {
      flex-direction: column;
    }
  }
  
  /* Styles for ChangeDeliveryFrequency component */
  .change-delivery-frequency {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
  }
  
  .change-delivery-frequency h2 {
    margin-bottom: 15px;
  }
  
  .frequency-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  .update-frequency-btn {
    width: 100%;
    padding: 10px;
    background-color: #4a69bd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-frequency-btn:hover {
    background-color: #3e5ca1;
  }

  .product-container {
    position: relative;
    min-height: 300px; /* Adjust based on your product component's typical height */
  }
  
  .product-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(248, 228, 212, 0.7); /* Light overlay matching the button color */
    color: #7b3f00;
    font-weight: bold;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #7b3f00;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }