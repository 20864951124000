.customize-delivery-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .customize-header, .how-to-customize, .why-customize {
    margin-bottom: 40px;
  }
  
  .customize-header h1, .how-to-customize h2, .why-customize h2, .user-reviews h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #2c3e50;
  }
  
  .customize-header p, .how-to-customize p, .why-customize p {
    font-size: 1.2rem;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .how-to-customize ul {
    list-style-type: disc;
    margin-left: 40px;
    color: #27ae60;
  }
  
  .user-reviews {
    text-align: center;
  }
  
  .user-reviews h2 {
    margin-bottom: 20px;
  }
  
  .user-review {
    text-align: center;
  }
  
  .user-review img {
    border-radius: 50%;
    max-width: 150px;
    margin-bottom: 10px;
  }
  
  .user-review h3 {
    font-size: 1.5rem;
    color: #34495e;
  }
  
  .user-review p {
    font-size: 1.1rem;
    color: #7f8c8d;
  }
  
  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }