.pictures-grid-section {
    padding:  0;
    background-color: #f9f9f9;
  }
  
  .pictures-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns in total */
    gap: 0;
    grid-auto-rows: 200px; /* Fixed row height for consistency */
  }
  
  /* Ensure all images have the same styling */
  .pictures-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Keep aspect ratio, cover the space */
    border-radius: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .pictures-grid img:hover {
    transform: scale(1.05); /* Hover effect */
  }
  
  /* First row: 4 images each in 1 column */
  .pictures-grid img:nth-child(1),
  .pictures-grid img:nth-child(2),
  .pictures-grid img:nth-child(3),
  .pictures-grid img:nth-child(4) {
    grid-column: span 1;
  }
  
  /* Second row:
    - Picture 5 spans the first 2 columns,
    - Picture 6 spans the next 2 columns,
    - Picture 7 spans the last 2 columns
  */
  .pictures-grid img:nth-child(5) {
    grid-column: 1 / 3; /* Span the first two columns */
  }
  
  .pictures-grid img:nth-child(6) {
    grid-column: 3 / 5; /* Span the next two columns */
  }
  
  .pictures-grid img:nth-child(7) {
    grid-column: 1 / 5; /* Span across all columns */
  }
  
  @media (max-width: 768px) {
    .pictures-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 equal columns on small screens */
    }
  
    /* Ensure all images in the second row span 2 columns on small screens */
    .pictures-grid img:nth-child(5),
    .pictures-grid img:nth-child(6),
    .pictures-grid img:nth-child(7) {
      grid-column: span 2;
    }
  }
  