/* Container holding both left and right columns */
.create-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: var(--linen-bg);
    background-size: cover;
    
    padding: 20px;
  }
  
  /* Left column for heading */
  .create-account-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    width: 40%;  /* 40% of the width for the left side */
  }
  
  /* Add spacing for the logo */
  .logo {
    margin-top: 20px;
    width: 150px;  /* Adjust the width of the logo */
    height: auto;
  }
  
  /* Vertical line separator */
  .vertical-line {
    width: 3px;
    background-color: #ccc;
    height: 60%;
    margin: 0 20px;

    opacity: 0;
    animation: fadeInLine 3s ease-in-out forwards;
  }

  /* Fade-in animation */
  @keyframes fadeInLine {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  
  /* Right column for the form */
  .create-account-right {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 60%;  /* 60% of the width for the right side */
    box-sizing: border-box;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #3a7bd5;
    outline: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #3a7bd5;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #1d61b1;
  }
  
  .error-message {
    color: #e74c3c;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #27ae60;
    margin-bottom: 10px;
  }
  
  @media (max-width: 800px) {
    /* On smaller screens, the layout becomes vertical */
    .create-account-container {
      flex-direction: column;
      padding: 10px;
    }
  
    /* Hide the vertical line on smaller screens */
    .vertical-line {
      display: none;
    }
  
    /* Make both sections 100% width on mobile */
    .create-account-left, .create-account-right {
      width: 100%;
      text-align: center;
    }
  
    .create-account-right {
      padding: 20px;
    }
  }