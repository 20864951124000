* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arvo";
}

html, body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* General Styling */
body {
  font-family: 'Arial', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Hero Section */
.hero {
  padding-top: 6rem;
  position: relative;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  margin-top: 3rem;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero-text {
  text-align: center;
  color: white;
}

.hero-text h2 {
  font-size: 48px;
}

.hero-text p {
  font-size: 18px;
  margin-top: 10px;
}

.cta-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: white;
  color: #4CAF50;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #f1f1f1;
}

/* Footer Section */
.footer {
  padding: 20px;
  text-align: center;
  background-color: #333;
  color: white;
}

.footer .social-media a {
  margin-left: 20px;
  color: white;
  font-size: 18px;
}

.footer .social-media a:hover {
  color: #4CAF50;
}


@media (max-width: 768px)  {
  .product-grid {
      flex-direction: column;
      gap: 40px;
  }

  .hero-text h2 {
      font-size: 32px;
  }

  .hero-text p {
      font-size: 16px;
  }
}

/* .SectionContainer {
  display: block;
  unicode-bidi: isolate;
  box-sizing: inherit;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
} */

.SectionContainer h1 {
  font-size: 2vw;
  color: #9C6D38;
  font-family: 'Archivo Black', arial, sans-serif;
}

.OrderButton {
  display: block;
  unicode-bidi: isolate;
  max-width: 100%;
  text-align: center;
  padding-bottom: 2rem;
}

.centered-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  text-align: center;
}

.OrderButton a {
  border-color: #9C6D38;
  color: #9C6D38;
  background-color: transparent;
  letter-spacing: 2px;
  border-style: solid;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 600;
  padding-right: 16px;
  padding-left: 16px;
  min-height: 56px;
  display: inline-flex;
  border-left-width: 1.3px;
  border-bottom-width: 1.3px;
  border-right-width: 1.3px;
  border-top-width: 1.3px;
  border-radius: 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.OrderButton a:hover {
   background-color: #DDA15E;
   color: #333;
}

@media (max-width: 1024px) {
  .hero {
    height: 70vh;
  }

  .SectionContainer h1 {
    font-size: 4vw;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 30vh;
  }

  .hero-video {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  .centered-container {
    height: 10vh;
  }
}


.top-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: var(--farm-shipping-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.top-section h1 {
  color: #F5F5DC;
  font-size: 2.5rem;
}

.top-section h2 {
  color: #F5F5DC;
  font-size: 1.5rem;
}



.deliveries-container {
    font-family: Arial, sans-serif;
  }
  
  .deliveries-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .deliveries-header h1 {
    font-size: 2.5rem;
    color: #f5f5f5;
  }
  
  .deliveries-header p {
    font-size: 1.2rem;
    color: #f5f5f5;
  }
  
  .deliveries-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: var(--wood-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .card-container .card {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 90%;
    max-width: 600px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    box-sizing: border-box;
  }

  .card-container:nth-child(odd) {
    transform: translateX(-20%);
  }
  .card-container:nth-child(even) {
    transform: translateX(20%);
  }
  
  .card img {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card-container .card h2 {
    font-size: 1.8rem;
    color: #f5f5f5;
    margin-bottom: 10px;
  }
  
  .card-container .card p {
    font-size: 1.2rem;
    color: #f0feff;
  }
  
  .arrow {
    font-size: 2rem;
    color: #f5f5f5;
    margin-bottom: 10px;
  }
  
  .faqs {
    margin-top: 40px;
  }
  
  .faqs h2 {
    font-size: 2rem;
    color: #34495e;
    text-align: center;
  }
  
  .faq-item h3 {
    font-size: 1.5rem;
    color: #27ae60;
  }
  
  .faq-item p {
    font-size: 1rem;
    color: #95a5a6;
  }
  
  .rounded-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 25px;
  }

  .rounded-button:hover {
    background-color: #45a049;
  }

  .cta-container {
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: var(--plate-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .cta-container a {
    margin-bottom: 7rem;
  }

  .button-83 {
    appearance: button;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #fff, #f8eedb);
    border: 0 solid #e5e7eb;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #482307;
    column-gap: 1rem;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 100%;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    outline: 2px solid transparent;
    padding: 1rem 1.5rem;
    text-align: center;
    text-transform: none;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81,41,10,0.1),0px 2px 2px rgba(81,41,10,0.2);
    opacity: .87;
  }
  
  .button-83:active {
    background-color: #f3f4f6;
    box-shadow: -1px 2px 5px rgba(81,41,10,0.15),0px 1px 1px rgba(81,41,10,0.15);
    transform: translateY(0.125rem);
  }
  
  .button-83:focus {
    box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 8px 10px rgba(81,41,10,0.1), 0px 2px 2px rgba(81,41,10,0.2);
  }


  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .card {
      width: 100%;
      transform: translateX(0);
    }

    .card-container:nth-child(even) {
      transform: translateX(5%);
    }
    .card-container:nth-child(odd) {
      transform: translateX(5%);
    }
  }