/* Container holding both left and right columns with a background image */
.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: var(--linen-bg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 20px;
}

/* Left column for heading and logo */
.forgot-password-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 40%;
    text-align: center;
}

.forgot-password-left h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.logo {
    max-width: 200px;
    height: auto;
    margin-top: 20px;
}

/* Vertical line separator */
.vertical-line {
    width: 1px;
    height: 400px;
    background-color: #ccc;
    margin: 0 20px;
}

/* Right column for form */
.forgot-password-right {
    width: 40%;
    padding: 20px;
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
}

input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

button {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

button:hover {
    background-color: #45a049;
}

.error-message {
    color: #f44336;
    margin-top: 10px;
}

.success-message {
    color: #4CAF50;
    margin-top: 10px;
}

.form-links {
    margin-top: 20px;
    text-align: center;
}

.form-links a {
    color: #4CAF50;
    text-decoration: none;
    margin: 0 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}

.form-links a:hover {
    text-decoration: underline;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .forgot-password-container {
        flex-direction: column;
        padding: 10px;
    }

    /* Hide the vertical line on smaller screens */
    .vertical-line {
        display: none;
    }

    /* Make both sections 100% width on mobile */
    .forgot-password-left,
    .forgot-password-right {
        width: 100%;
        text-align: center;
    }

    .forgot-password-right {
        padding: 20px;
    }
}