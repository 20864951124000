/* Landing Page Styles */
.landing-container {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Hero Section */
.hero {
  position: relative;
  background-size: cover !important;
  background-position: center !important;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headline {
  font-size: 4rem;
  font-weight: 700;
  max-width: 800px;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  text-align: center;
}

.subheadline {
  font-size: 1.5rem;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.4;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.hero-features {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.hero-feature {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

/* Value Props Section */
.value-props {
  padding: 5rem 2rem;
  text-align: center;
  background-image: var(--wood-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 700px;
  margin: 0 auto 3rem;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.value-props-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
}

.value-prop-card {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.value-prop-icon {
  color: #27ae60;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Testimonials Section */
.testimonials {
  background: #f8f9fa;
  padding: 5rem 2rem;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.testimonial-stars {
  color: #f1c40f;
  margin-bottom: 1rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.author-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* CTA Section */
.cta-section {
  padding: 4rem 2rem;
  text-align: center;
  background-image: var(--cta-bg);
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Add text container with background */
.cta-content {
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.cta-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

/* Responsive Design */
@media (max-width: 768px) {
  .headline {
    font-size: 3rem;
  }
  
  .hero {
    padding: 4rem 1.5rem;
    margin-top: 0;
  }
  
  .hero-features {
    flex-direction: column;
    gap: 1rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .headline {
    font-size: 2.5rem;
  }
  
  .subheadline {
    font-size: 1.2rem;
  }
  
  .value-props-grid {
    grid-template-columns: 1fr;
  }
}

/* Trust Elements Section */
.trust-elements {
  padding: 5rem 0;
  background-image: var(--linen-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.trust-elements h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #36454F;
}

.testimonials {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .trust-elements h2 {
    font-size: 2rem;
  }
  
  .testimonials {
    padding: 0;
  }
}

/* Urgency Banner */
.urgency-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: linear-gradient(90deg, #E8DCC4 0%, #FFF8E7 100%);
  color: #7b3f00;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f5e6d3;
  border-bottom: 2px solid #d4a373;
}

.urgency-banner-text {
  margin-right: 1rem;
}

.urgency-banner-text-mobile {
  display: none;
}

.urgency-banner-cta {
  background-color: #7b3f00;
  color: #FFF8E7;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.urgency-banner-cta:hover {
  background-color: #8b4513;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .urgency-banner {
    padding: 15px 10px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
  
  .urgency-banner-text {
    display: none;
  }
  
  .urgency-banner-text-mobile {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 15px;
  }
  
  .urgency-banner-cta {
    width: 100%;
    max-width: 200px;
    margin: 5px auto;
  }
}

.cta-primary {
  background-color: #E8A87C; /* Warm, inviting coral color */
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #e69b68; /* Slightly darker on hover */
}

.cta-primary:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add subtle gradient overlay */
.cta-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

@media (max-width: 768px) {
  .cta-primary,
  .hero .cta-primary {
    font-size: 1rem;
    padding: 0.875rem 1.5rem;
    width: auto;
    min-width: 200px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .hero .cta-primary {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* Remove gradient overlay on mobile */
  .hero .cta-primary::before {
    display: none;
  }
}

/* Smaller mobile devices */
@media (max-width: 480px) {
  .cta-primary,
  .hero .cta-primary {
    font-size: 0.9rem;
    padding: 0.75rem 1.25rem;
    min-width: 180px;
  }
}

/* Update hero section CTA button */
.hero .cta-primary {
  margin-top: 2rem; /* Add some spacing from the text above */
  margin-bottom: 2rem; /* Add spacing before the features */
  background-color: #E8A87C;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  padding-bottom: 2.5rem;
}

/* Ensure both buttons have the same hover and active states */
.hero .cta-primary:hover,
.cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #e69b68;
}

.hero .cta-primary:active,
.cta-primary:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add gradient overlay to both buttons */
.hero .cta-primary::before,
.cta-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

/* Value Props Section - Mobile Carousel */
@media (max-width: 768px) {
  .value-props-grid {
    position: relative;
    height: 400px; /* Adjust based on card height */
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .value-prop-card {
    position: relative; /* Change from absolute to relative */
    width: 90%;
    margin: 0 auto 1rem;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  .value-prop-card.active {
    opacity: 1;
  }
}

/* Hide urgency banner on mobile */
@media (max-width: 768px) {
    .urgency-banner {
        display: none;
    }
}